import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../../Css/Home.css";
import SkeletonStory from "../Skeletons/SkeletonStory";
import CardStory from "../StoryScreens/CardStory";
import NoStories from "../StoryScreens/NoStories";
import Pagination from "./Pagination";

import { useNavigate } from "react-router-dom";
const Home = () => {
  const search = useLocation().search;
  const searchKey = new URLSearchParams(search).get("search");
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    document.title = "Ulai docs";
    const getStories = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `https://docs.ulai.in/backend/story/getAllStories?search=${
            searchKey || ""
          }&page=${page}`
        );

        // if (searchKey) {
        //   navigate({
        //     pathname: "/",
        //     search: `?search=${searchKey}${page > 1 ? `&page=${page}` : ""}`,
        //   });
        // } else {
        //   navigate({
        //     pathname: "/",
        //     search: `${page > 1 ? `page=${page}` : ""}`,
        //   });
        // }
        setStories(data.data);
        setPages(data.pages);

        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    getStories();
  }, [searchKey, page]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("search");
    console.log(" window.locatiosdfsdfsfn", window.location);
    if (!myParam && window.location.pathname == "/") {
      console.log(" window.location", window.location);
      setTimeout(() => {
        navigate("/story/getting-started");
      }, 1000);
    }
  }, [window?.location?.pathname]);
  // useEffect(() => {

  // }, [searchKey]);
  useEffect(() => {
    setPage(1);
  }, [searchKey]);

  return (
    <div className="Inclusive-home-page">
      {loading ? (
        <div className="skeleton_emp">
          {[...Array(6)].map(() => {
            return (
              // theme dark :> default : light
              <SkeletonStory key={uuidv4()} />
            );
          })}
        </div>
      ) : (
        <div>
          <div className="story-card-wrapper">
            {stories.length !== 0 ? (
              stories.map((story) => {
                return <CardStory key={uuidv4()} story={story} />;
              })
            ) : (
              <NoStories />
            )}
            <img className="bg-planet-svg" src="planet.svg" alt="planet" />
            <img className="bg-planet2-svg" src="planet2.svg" alt="planet" />
            <img className="bg-planet3-svg" src="planet3.svg" alt="planet" />
          </div>

          <Pagination page={page} pages={pages} changePage={setPage} />
        </div>
      )}
      <br />
    </div>
  );
};

export default Home;
