import axios from "axios";
import React, { useEffect, useState } from "react";

export const AuthContext = React.createContext();

const AuthContextProvider = (props) => {
  const [activeUser, setActiveUser] = useState({});
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [config, setConfig] = useState({
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

  useEffect(() => {
    const controlAuth = async () => {
      try {
        const { data } = await axios.get(
          "https://docs.ulai.in/backend/auth/private",
          config
        );
        setActiveUser(data.user);
      } catch (error) {
        localStorage.removeItem("authToken");

        setActiveUser({});
      }
    };
    controlAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        activeUser,
        setActiveUser,
        config,
        setConfig,
        toggleDrawer,
        setOpen,
        open,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
