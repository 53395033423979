import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // e.preventDefault() ;
    // if(searchTerm){
    navigate(`/?search=${e}`);
    // }

    setSearchTerm(e);
  };
  const handleSubmitClick = async () => {
    // e.preventDefault() ;
    // if(searchTerm){
    navigate(`/?search=${searchTerm}`);
    // }

    // setSearchTerm(e);
  };

  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      const context = this;

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  return (
    <form className="search-form" onSubmit={handleSubmitClick}>
      <input
        type="text"
        name="search"
        placeholder="Search..."
        className=" m-4"
        onChange={(e) => handleSubmit(e.target.value)}
        value={searchTerm}
      />

      <button
        type="submit"
        className={searchTerm.trim() ? "searchBtn" : "disBtn"}
      >
        <i>
          {" "}
          <BiSearchAlt2 />{" "}
        </i>{" "}
      </button>
    </form>
  );
};

export default SearchForm;
