import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext, useEffect, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { BsBookmarks } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/Header.css";
import Logo from "../../ulaiLogo.png";
import SkeletonElement from "../Skeletons/SkeletonElement";
import SearchForm from "./SearchForm";
const Header = () => {
  const bool = localStorage.getItem("authToken") ? true : false;
  const [auth, setAuth] = useState(bool);
  const { activeUser, toggleDrawer, setOpen } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setAuth(bool);
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, [bool]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <header>
      <div className="averager" style={{ alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            width: "10%",
            gap: "10px",
          }}
        >
          <div className="hamburger">
            <MenuIcon
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
          <Link to="/story/getting-started" className="logo " style={{ fontSize: "24px" }}>
            <img src={Logo} style={{ width: "100px" }} />
          </Link>
          <Link
            to="/story/getting-started"
            className="logo "
            style={{ fontSize: "20px", fontWeight: 600 }}
          >
            DOCS
          </Link>
        </div>
        <SearchForm />
        <div className="header_options">
          {auth ? (
            activeUser.email == "admin@ulai.in" ? (
              <div className="auth_options">
                <Link className="addStory-link" to="/addstory">
                  <RiPencilFill /> Add Story{" "}
                </Link>

                <Link to="/readList" className="readList-link">
                  <BsBookmarks />
                  <span id="readListLength">{activeUser.readListLength}</span>
                </Link>
                <div className="header-profile-wrapper ">
                  {loading ? (
                    <SkeletonElement type="minsize-avatar" />
                  ) : (
                    <img
                      src={`https://docs.ulai.in/backend/userPhotos/${activeUser.photo}`}
                      alt={activeUser.username}
                    />
                  )}

                  <div className="sub-profile-wrap  ">
                    <Link className="profile-link" to="/profile">
                      {" "}
                      <FaUserEdit /> Profile{" "}
                    </Link>

                    <button className="logout-btn" onClick={handleLogout}>
                      {" "}
                      <BiLogOut /> Logout
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="auth_options">
                {/* <Link className="addStory-link" to="/addstory">
                  <RiPencilFill /> Add Story{" "}
                </Link> */}

                <Link to="/readList" className="readList-link">
                  <BsBookmarks />
                  <span id="readListLength">{activeUser.readListLength}</span>
                </Link>
                <div className="header-profile-wrapper ">
                  {loading ? (
                    <SkeletonElement type="minsize-avatar" />
                  ) : (
                    <img
                      src={`https://docs.ulai.in/backend/userPhotos/${activeUser.photo}`}
                      alt={activeUser.username}
                    />
                  )}

                  <div className="sub-profile-wrap  ">
                    <Link className="profile-link" to="/profile">
                      {" "}
                      <FaUserEdit /> Profile{" "}
                    </Link>

                    <button className="logout-btn" onClick={handleLogout}>
                      {" "}
                      <BiLogOut /> Logout
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="noAuth_options">
              <Link className="login-link" to="/login">
                {" "}
                Login{" "}
              </Link>

              <Link className="register-link" to="/register">
                {" "}
                Get Started
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
