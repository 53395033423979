// import "@ckeditor/ckeditor5-build-classic/build/translations/en"; // Optional translation setup
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import CKEditor from "react-ckeditor-component";

import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/AddStory.css";
// const editorConfig = {
//   plugins: [Image, ImageUpload],
//   toolbar: ["undo", "redo", "imageUpload", "|", "bold", "italic", "link"],
//   image: {
//     toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
//   },
//   // Configure image upload adapter here if necessary
// };

const AddStory = () => {
  const { config } = useContext(AuthContext);
  const imageEl = useRef(null);
  const editorEl = useRef(null);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [sequence, setSequence] = useState(1);
  const [allCategory, setAllCategory] = useState([]);
  const [content, setContent] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const clearInputs = () => {
    setTitle("");
    setContent("");
    setImage("");
    editorEl.current.editor.setData("");
    imageEl.current.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("image", image);
    formdata.append("categoryName", categoryName);
    formdata.append("sequence", sequence);
    formdata.append("content", content);

    try {
      const { data } = await axios.post(
        "https://docs.ulai.in/backend/story/addstory",
        formdata,
        config
      );
      setSuccess("Add story successfully ");

      clearInputs();
      setTimeout(() => {
        setSuccess("");
      }, 7000);
    } catch (error) {
      setTimeout(() => {
        setError("");
      }, 7000);
      setError(error.response.data.error);
    }
  };
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://docs.ulai.in/backend/category/getAll"
      );
      setAllCategory(data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCategory();
    document.title = "Add Page";
  }, []);
  return (
    <div className="Inclusive-addStory-page ">
      <Link to={"/"}>
        <FiArrowLeft />
      </Link>
      <form onSubmit={handleSubmit} className="addStory-form">
        {error && <div className="error_msg">{error}</div>}
        {success && (
          <div className="success_msg">
            <span>{success}</span>
            <Link to="/">Go home</Link>
          </div>
        )}
        <div>
          <label>
            Category
            <select
              value={categoryName}
              style={{ width: "100%" }}
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
            >
              <option value={``}></option>;
              {allCategory.map((el) => {
                return (
                  <option value={`${el.title}`} key={el.title}>
                    {el.title}
                  </option>
                );
              })}
            </select>
          </label>
          <label>
            Sequence
            <select
              value={sequence}
              style={{ width: "100%" }}
              onChange={(e) => {
                setSequence(e.target.value);
              }}
            >
              {" "}
              <option value={''} key={""}></option>;<option value={1}>1</option>
              ;<option value={2}>2</option>;<option value={3}>3</option>;
              <option value={4}>4</option>;<option value={5}>5</option>;
              <option value={6}>6</option>;<option value={7}>7</option>;
              <option value={8}>8</option>;<option value={9}>9</option>;
              <option value={10}>10</option>;
            </select>
          </label>
        </div>

        <input
          type="text"
          required
          id="title"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <CKEditor
          // editor={ClassicEditor}
          // onChange={(e, editor) => {}}
          events={{
            // blur: (e) => onBlur(e),
            // afterPaste: (e) => afterPaste(e),
            change: (e) => {
              console.log("sdfs", e.editor.getData());
              const data = e.editor.getData();
              setContent(data);
            },
          }}
          content={content}
          // ref={editorEl}
        />
        <div class="StoryImageField">
          <AiOutlineUpload />
          <div class="txt">
            {image
              ? image.name
              : " Include a high-quality image in your story to make it more inviting to readers."}
          </div>
          <input
            name="image"
            type="file"
            ref={imageEl}
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        </div>
        <button
          type="submit"
          disabled={image && categoryName  && sequence? false : true}
          className={image ? "addStory-btn" : "dis-btn"}
        >
          Publish{" "}
        </button>
      </form>
    </div>
  );
};

export default AddStory;
