import { Box, Drawer } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BsBookmarkFill, BsBookmarkPlus, BsThreeDots } from "react-icons/bs";
import { FaHeart, FaRegComment, FaRegHeart } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/DetailStory.css";
import CommentSidebar from "../CommentScreens/CommentSidebar";
import Loader from "../GeneralScreens/Loader";
const slugify = require("slugify");

const DetailStory = () => {
  // const { open, toggleDrawer } = appstore();
  //   const [open]
  // const open=false
  // const toggleDrawer=false
  // console.log("open", open);
  const { open, toggleDrawer } = useContext(AuthContext);

  const [likeStatus, setLikeStatus] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [activeUser, setActiveUser] = useState({});
  const [story, setStory] = useState({});
  const [storyLikeUser, setStoryLikeUser] = useState([]);
  const [sidebarShowStatus, setSidebarShowStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const slug = useParams().slug;
  const [storyReadListStatus, setStoryReadListStatus] = useState(false);
  const navigate = useNavigate();
  const [stories, setStories] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    const getDetailStory = async () => {
      setLoading(true);
      var activeUser = {};
      try {
        const { data } = await axios.get(
          "https://docs.ulai.in/backend/auth/private",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        activeUser = data.user;

        setActiveUser(activeUser);
      } catch (error) {
        setActiveUser({});
      }

      try {
        const { data } = await axios.post(
          `https://docs.ulai.in/backend/story/${slug}`,
          { activeUser }
        );
        setStory(data.data);
        document.title = data?.data?.title;
        setLikeStatus(data.likeStatus);
        setLikeCount(data.data.likeCount);
        setStoryLikeUser(data.data.likes);
        setLoading(false);

        const story_id = data.data._id;

        if (activeUser.readList) {
          if (!activeUser.readList.includes(story_id)) {
            setStoryReadListStatus(false);
          } else {
            setStoryReadListStatus(true);
          }
        }
      } catch (error) {
        setStory({});
        navigate("/not-found");
      }
    };
    getDetailStory();
  }, [slug, setLoading]);

  const handleLike = async () => {
    setTimeout(() => {
      setLikeStatus(!likeStatus);
    }, 1500);

    try {
      const { data } = await axios.post(
        `https://docs.ulai.in/backend/story/${slug}/like`,
        { activeUser },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setLikeCount(data.data.likeCount);
      setStoryLikeUser(data.data.likes);
    } catch (error) {
      setStory({});
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Do you want to delete this post")) {
      try {
        await axios.delete(
          `https://docs.ulai.in/backend/story/${slug}/delete`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editDate = (createdAt) => {
    const d = new Date(createdAt);
    var datestring =
      d.toLocaleString("eng", { month: "long" }).substring(0, 3) +
      " " +
      d.getDate();
    return datestring;
  };

  const addStoryToReadList = async () => {
    try {
      const { data } = await axios.post(
        `https://docs.ulai.in/backend/user/${slug}/addStoryToReadList`,
        { activeUser },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setStoryReadListStatus(data.status);

      document.getElementById("readListLength").textContent =
        data.user.readListLength;
    } catch (error) {
      console.log(error);
    }
  };
  const getStories = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://docs.ulai.in/backend/story/getAllStories`
      );
      setStories(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://docs.ulai.in/backend/category/getAllCategory"
      );
      setAllCategory(data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getStories();
    getAllCategory();
  }, []);
  // min-height: 850px;
  // width: 1050px;
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="detaileddTory"
            style={{
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #00000033",
                position: "sticky",
                top: "0", // This is essential for sticky to work
                height: "84vh", // Ensure height is set or scrollable
                overflow: "auto",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
              className="listBorder"
            >
              {allCategory
                .sort(function (a, b) {
                  return a.sequence - b.sequence;
                })
                .map((el) => {
                  return (
                    <>
                      {" "}
                      <div
                        style={{
                          marginLeft: "20px",
                          marginBottom: "8px",
                          color: "#6b6b6b",
                        }}
                        onClick={() => {
                          if (el.categoryName == "GETTING STARTED") {
                            navigate("/story/getting-started");
                          }
                        }}
                      >
                        {" "}
                        {el.categoryName}{" "}
                      </div>
                      <ul
                        style={{
                          listStyle: "none",
                          marginLeft: "0%",
                          paddingLeft: "0px",
                        }}
                      >
                        {el.listofslugs
                          .sort(function (a, b) {
                            return a.sequence - b.sequence;
                          })
                          .map((elem) => {
                            return (
                              <li>
                                <div
                                  className="slug"
                                  style={{
                                    // border: "1px solid red",
                                    cursor: "pointer",
                                    color:
                                      story?.title == elem?.title
                                        ? "rgb(22, 22, 225)"
                                        : "",
                                    paddingBottom: "2px",
                                    // borderBottom: "1px solid #95959533",
                                  }}
                                  onClick={() => {
                                    let newUrl = elem?.title.replace(" ", "");
                                    console.log("sadsad", newUrl);
                                    navigate(
                                      `/story/${slugify(elem?.title, {
                                        replacement: "-",
                                        remove: /[*+~.()'"!:@/?]/g,
                                        lower: true,
                                        strict: false,
                                        locale: "tr",
                                        trim: true,
                                      })}`
                                    );
                                  }}
                                >
                                  {elem?.title}
                                </div>
                              </li>
                            );
                          })}{" "}
                      </ul>{" "}
                    </>
                  );
                })}
            </div>
            <div className="Inclusive-detailStory-page">
              <div className="top_detail_wrapper" style={{ display: "flex" }}>
                {/* <Link to={"/"}>
                  <FiArrowLeft />
                </Link> */}
                <h5>{story.title}</h5>
              </div>

              <div className="CommentFieldEmp">
                <CommentSidebar
                  slug={slug}
                  sidebarShowStatus={sidebarShowStatus}
                  setSidebarShowStatus={setSidebarShowStatus}
                  activeUser={activeUser}
                />
              </div>

              <div className="story-content">
                {/* <div className="story-banner-img">
                  <img src={`/storyImages/${story.image}`} alt={story.title} />

                </div> */}

                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: story.content }}
                ></div>
              </div>
              <div className="story-general-info">
                <ul>
                  {story.author && (
                    <li className="story-author-info">
                      <img
                        src={`https://docs.ulai.in/backend/userPhotos/${story.author.photo}`}
                        alt={story.author.username}
                      />
                      <span className="story-author-username">
                        {story.author.username}{" "}
                      </span>
                    </li>
                  )}
                  <li className="story-createdAt">
                    {editDate(story.createdAt)}
                  </li>
                  <b>-</b>

                  <li className="story-readtime">{story.readtime} min read</li>
                </ul>

                {!activeUser.username && (
                  <div className="comment-info-wrap">
                    <i
                      onClick={(prev) => {
                        setSidebarShowStatus(!sidebarShowStatus);
                      }}
                    >
                      <FaRegComment />
                    </i>

                    <b className="commentCount">{story.commentCount}</b>
                  </div>
                )}

                {activeUser &&
                story.author &&
                story.author._id === activeUser._id ? (
                  <div className="top_story_transactions">
                    <Link
                      className="editStoryLink"
                      to={`/story/${story.slug}/edit`}
                    >
                      <FiEdit />
                    </Link>
                    <span className="deleteStoryLink" onClick={handleDelete}>
                      <RiDeleteBin6Line />
                    </span>
                  </div>
                ) : null}
              </div>
              {activeUser.username && (
                <div className="fixed-story-options">
                  <ul>
                    <li>
                      <i onClick={handleLike}>
                        {likeStatus ? (
                          <FaHeart color="#0063a5" />
                        ) : (
                          <FaRegHeart />
                        )}
                      </i>

                      <b
                        className="likecount"
                        style={
                          likeStatus
                            ? { color: "#0063a5" }
                            : { color: "rgb(99, 99, 99)" }
                        }
                      >
                        {" "}
                        {likeCount}
                      </b>
                    </li>

                    <li>
                      <i
                        onClick={(prev) => {
                          setSidebarShowStatus(!sidebarShowStatus);
                        }}
                      >
                        <FaRegComment />
                      </i>

                      <b className="commentCount">{story.commentCount}</b>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <i onClick={addStoryToReadList}>
                        {storyReadListStatus ? (
                          <BsBookmarkFill color="#0205b1" />
                        ) : (
                          <BsBookmarkPlus />
                        )}
                      </i>
                    </li>

                    <li className="BsThreeDots_opt">
                      <i>
                        <BsThreeDots />
                      </i>

                      {activeUser && story.author?._id === activeUser?._id ? (
                        <div className="delete_or_edit_story  ">
                          <Link
                            className="editStoryLink"
                            to={`/story/${story?.slug}/edit`}
                          >
                            <p>Edit Story</p>
                          </Link>
                          <div
                            className="deleteStoryLink"
                            onClick={handleDelete}
                          >
                            <p>Delete Story</p>
                          </div>
                        </div>
                      ) : null}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{
                width: 250,
                height: "10 0vh",
                padding: "20px",
                overflow: "auto",
              }}
              role="presentation"
              onClick={toggleDrawer(false)}
            >
              {allCategory
                .sort(function (a, b) {
                  return a.sequence - b.sequence;
                })
                .map((el) => {
                  return (
                    <>
                      {" "}
                      <div
                        style={{
                          marginLeft: "20px",
                          marginBottom: "8px",
                          color: "#6b6b6b",
                        }}
                      >
                        {" "}
                        {el.categoryName}{" "}
                      </div>
                      <ul
                        style={{
                          listStyle: "none",
                          marginLeft: "0%",
                          paddingLeft: "0px",
                        }}
                      >
                        {el.listofslugs
                          .sort(function (a, b) {
                            return a.sequence - b.sequence;
                          })
                          .map((elem) => {
                            return (
                              <li>
                                <div
                                  className="slug"
                                  style={{
                                    // border: "1px solid red",
                                    cursor: "pointer",
                                    color:
                                      story?.title == elem?.title
                                        ? "rgb(22, 22, 225)"
                                        : "",
                                    paddingBottom: "2px",
                                    // borderBottom: "1px solid #95959533",
                                  }}
                                  onClick={() => {
                                    let newUrl = elem?.title.replace(" ", "");
                                    console.log("sadsad", newUrl);
                                    navigate(
                                      `/story/${slugify(elem?.title, {
                                        replacement: "-",
                                        remove: /[*+~.()'"!:@/?]/g,
                                        lower: true,
                                        strict: false,
                                        locale: "tr",
                                        trim: true,
                                      })}`
                                    );
                                  }}
                                >
                                  {elem?.title}
                                </div>
                              </li>
                            );
                          })}{" "}
                      </ul>{" "}
                    </>
                  );
                })}
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};

export default DetailStory;
