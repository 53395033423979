// import { CKEditor } from "@ckeditor/ckeditor5-react";
import CKEditor from "react-ckeditor-component";

import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/EditStory.css";
import Loader from "../GeneralScreens/Loader";

const EditStory = () => {
  const { config } = useContext(AuthContext);
  const slug = useParams().slug;
  const imageEl = useRef(null);
  const [loading, setLoading] = useState(true);
  const [story, setStory] = useState({});
  const [image, setImage] = useState("");
  const [previousImage, setPreviousImage] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [sequence, setSequence] = useState(1);
  const [allCategory, setAllCategory] = useState([]);
  const navigate = useNavigate();
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://docs.ulai.in/backend/category/getAll"
      );
      setAllCategory(data.data);
    } catch (error) {}
  };
  useEffect(() => {
    const getStoryInfo = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `https://docs.ulai.in/backend/story/editStory/${slug}`,
          config
        );
        setStory(data.data);
        setTitle(data.data.title);
        setSequence(data.data.sequence)
        setCategoryName(data.data.categoryName)
        setContent(data.data.content);
        setImage(data.data.image);
        setPreviousImage(data.data.image);
        setLoading(false);
      } catch (error) {
        navigate("/");
      }
    };
    document.title = "Edit Page";
    getStoryInfo();
    getAllCategory();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("image", image);
    formdata.append("categoryName", categoryName);
    formdata.append("sequence", sequence);
    formdata.append("previousImage", previousImage);

    try {
      const { data } = await axios.put(
        `https://docs.ulai.in/backend/story/${slug}/edit`,
        formdata,
        config
      );

      setSuccess("Edit Story successfully ");

      setTimeout(() => {
        navigate("/");
      }, 2500);
    } catch (error) {
      setTimeout(() => {
        setError("");
      }, 4500);
      setError(error.response.data.error);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Inclusive-editStory-page ">
          <form onSubmit={handleSubmit} className="editStory-form">
            {error && <div className="error_msg">{error}</div>}
            {success && (
              <div className="success_msg">
                <span>{success}</span>
                <Link to="/">Go home</Link>
              </div>
            )}
            <div>
              <label>
                Category
                <select
                  value={categoryName}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                >
                  <option value={``} key={""}></option>;
                  {allCategory.map((el) => {
                    return (
                      <option value={`${el.title}`} key={el.title}>
                        {el.title}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label>
                Sequence
                <select
                  value={sequence}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setSequence(e.target.value);
                  }}
                >
                  {" "}
                  <option value={""} ></option>;
                  <option value={1}>1</option>;<option value={2}>2</option>;
                  <option value={3}>3</option>;<option value={4}>4</option>;
                  <option value={5}>5</option>;<option value={6}>6</option>;
                  <option value={7}>7</option>;<option value={8}>8</option>;
                  <option value={9}>9</option>;<option value={10}>10</option>;
                </select>
              </label>
            </div>
            <input
              type="text"
              required
              id="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />

            <CKEditor
              // editor={ClassicEditor}
              // onChange={(e, editor) => {}}
              config="{{versionCheck: false}}"
              events={{
                // blur: (e) => onBlur(e),
                // afterPaste: (e) => afterPaste(e),
                change: (e) => {
                  console.log("sdfs", e.editor.getData());
                  const data = e.editor.getData();
                  setContent(data);
                },
              }}
              content={content}
              // ref={editorEl}
            />
            <div class="currentlyImage">
              <div class="absolute">Currently Image</div>
              <img
                src={`https://docs.ulai.in/backend/storyImages/${previousImage}`}
                alt="storyImage"
              />
            </div>
            <div class="StoryImageField">
              <AiOutlineUpload />
              <div class="txt">
                {image === previousImage
                  ? "    Change the image in your story "
                  : image.name}
              </div>
              <input
                name="image"
                type="file"
                ref={imageEl}
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>

            <button type="submit" className="editStory-btn">
              Edit Story{" "}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditStory;
